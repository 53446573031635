import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import cn from "classnames";
import { Radius } from "lucide-react";
import {
  Fragment, useEffect, useState
} from "react";

/**
 *
 * @param props - The root object
 * @param props.onChange - The root object
 * @param props.radius - The root object
 * @param props.className - The root object
 * @example
 */
export default function RadiusDropdown({
  className, onChange, radius
}) {
  const [rangeValue, setRangeValue] = useState(radius || 2);

  /**
   *
   * @param e
   * @example
   */
  function handleChange(e) {
    setRangeValue(e.target.value);
  }

  /**
   *
   * @param rangeValue
   * @example
   */
  function calcLeft(rangeValue) {
    const newValue = Number((rangeValue - 0.1) * 100 / (25 - 0.1));
    const newPosition = 24 - (newValue * 0.48);

    return `calc(${newValue}% + (${newPosition}px))`;
  }

  /**
   *
   * @param closePopup
   * @example
   */
  function handleSave(closePopup) {
    onChange(rangeValue);
    closePopup();
  }

  useEffect(() => {
    setRangeValue(radius);
  }, [radius]);

  return (
    <div className={className}>
      <Popover className="relative">
        {({ open }) => (
          <div>
            <Popover.Button
              className={cn("flex px-2.5 py-0.5 justify-center items-center text-center border text-gray-600 bg-secondary-dark transition duration-300 hover:bg-secondary-darker border-secondary-darker group rounded text-xs font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 h-[23px]", {
                "border-gray-200 bg-gray-200": open,
                "border-gray-300 hover:bg-gray-50": !open
              })}
            >
              <Radius className="size-4" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                unmount
                className="absolute -left-52 top-8 z-40 w-80 lg:-left-[137px]"
              >
                {({ close, open }) => (
                  <div className="h-44 overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">

                    <div className="flex items-center justify-between border-b border-gray-200 p-3">
                      <p className="font-medium text-gray-800">Radius (km)</p>

                      <button className="text-gray-500 hover:text-gray-700 focus:outline-none" onClick={close} type="button">
                        <XMarkIcon className="size-5" />
                      </button>
                    </div>

                    <div className="relative mt-8 w-full px-4 py-2">
                      <div className="range-value" style={{ left: calcLeft(rangeValue) }}>
                        <span>{rangeValue}</span>
                      </div>

                      <input className="slider h-1.5 w-full appearance-none rounded bg-secondary-dark outline-none" max="25" min="0.1" onChange={handleChange} step={0.1} type="range" value={rangeValue} />
                    </div>

                    <div className="mt-2 px-4">

                      <button className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-8 py-1 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none" onClick={() => handleSave(close)} type="button">
                        Speichern
                      </button>

                    </div>

                  </div>
                )}
              </Popover.Panel>

            </Transition>

          </div>
        )}
      </Popover>
    </div>
  );
}
