import { ScaleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import useStore from "~/src/hooks/use-store";

/**
 *
 * @example
 */
const AreaSettingsButton = () => {
  const toggleAreaModal = useStore((state) => state.toggleAreaModal);

  const handleClick = () => {
    toggleAreaModal();
  };

  return (
    <>
      <span
        className="size-8 cursor-pointer rounded-full bg-custom-area-ratio p-1 hover:bg-custom-area-ratio-dark"
        data-tooltip-content="Flächengewichtung"
        data-tooltip-id="tooltip-gewichtung"
        onClick={handleClick}
      >
        <ScaleIcon className="w-full text-white" />
      </span>

      <Tooltip
        className="z-50"
        delayShow={500}
        id="tooltip-gewichtung"
        place="bottom"
      />
    </>
  );
};

export default AreaSettingsButton;
