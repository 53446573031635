import { ScaleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import priceTerms from "~/src/lib/price-terms.js";

import { formatEuro } from "~/src/modules/formatters";
import { priceLabels } from "~/src/modules/labels.js";

const {
  investment: investmentTerm,
  normal: normalTerm,
  perSquareMeter: perSquareMeterTerm,
  price: priceTerm
} = priceTerms;

const getPricePerSqm = (price, area) => {
  const hasValue = area > 0 && Number.isFinite(price);

  if (!hasValue) {
    return null;
  }

  return price / area;
};

/**
 *
 * @param root0
 * @param root0.unit
 * @param root0.showCustom
 * @param root0.customArea
 * @example
 */

/**
 *
 * @param props0 - The root object
 * @param props0.customArea - The root object
 * @param props0.showCustom - The root object
 * @param props0.unit - The root object
 * @example
 */
export default function UnitAvgOfferPrices({
  customArea, showCustom, unit
}) {
  const area = customArea ?? unit.offer_area;
  const offerPrices = [];

  const offerNormalPerSqm = getPricePerSqm(unit.offer_price_normal, area);

  if (offerNormalPerSqm !== null) {
    offerPrices.push(
      <div>
        <p className="font-bold">{formatEuro(offerNormalPerSqm)}</p>

        <p className="text-xs leading-none">{normalTerm}</p>
      </div>
    );
  }

  const offerInvestmentPerSqm = getPricePerSqm(unit.offer_price_investor, area);

  if (offerInvestmentPerSqm !== null) {
    offerPrices.push(
      <div>
        <p className="font-bold">{formatEuro(offerInvestmentPerSqm)}</p>

        <p className="text-xs leading-none">{investmentTerm}</p>
      </div>
    );
  }

  const rentPrices = [];

  const rentBasePerSqm = getPricePerSqm(unit.rent_netto, area);

  if (rentBasePerSqm !== null) {
    rentPrices.push(
      <div>
        <p className="font-bold">{formatEuro(rentBasePerSqm)}</p>

        <p className="text-xs leading-none">{priceLabels.rentNet}</p>
      </div>
    );
  }

  const rentTotalPerSqm = getPricePerSqm(unit.rent_bk, area);

  if (rentTotalPerSqm !== null) {
    rentPrices.push(
      <div>
        <p className="font-bold">{formatEuro(rentTotalPerSqm)}</p>

        <p className="text-xs leading-none">{priceLabels.rentTotal}</p>
      </div>
    );
  }

  const prices = [];

  if (unit.buyable) {
    prices.push(...offerPrices);
  }

  if (unit.rentable) {
    prices.push(...rentPrices);
  }

  if (prices.length === 0) {
    prices.push(<p className="font-bold">-</p>);
  }

  return (
    <div className="relative bg-gray-200 p-2 text-center text-sm text-gray-500">
      {showCustom && (Number.parseFloat(customArea).toFixed(2).toString() !== unit.offer_area) && (
        <div className="absolute right-2 top-2">
          <div>
            <div
              data-tooltip-content="Preis / m² aus individueller Gewichtung berechnet"
              data-tooltip-id="tooltip-unit-offer-avg-price"
              tabIndex="-1"
            >
              <ScaleIcon
                className="-mt-0.5 size-6 rounded-full bg-custom-area-ratio p-0.5 text-white focus:outline-none"
                tabIndex="-1"
              />
            </div>

            <Tooltip
              className="z-50"
              delayShow={500}
              id="tooltip-unit-offer-avg-price"
              place="bottom"
            />
          </div>
        </div>
      )}

      <p>{priceTerm}{perSquareMeterTerm}</p>

      <div className="mt-0.5 space-y-3">{prices}</div>
    </div>
  );
}
