import {
  Dialog, DialogBackdrop, DialogPanel, TransitionChild
} from "@headlessui/react";
import { ScaleIcon } from "@heroicons/react/24/outline";
import {
  useEffect, useRef, useState
} from "react";

import { showError, showSuccess } from "~/src/modules/toast-helper";

import AreaSettingsSliderContainer from "~/src/features/area-settings-slider-container";

import useStore from "~/src/hooks/use-store";
import useUserSettings from "~/src/hooks/use-user-settings";

import CloseIcon from "~/src/ui/icons/close-icon";

import { updateUserSettings } from "./handlers";

const sliders = [
  {
    key: "loggia",
    title: "Loggia"
  },
  {
    key: "balkon",
    title: "Balkon"
  },
  {
    key: "terrasse",
    title: "Terrasse"
  },
  {
    key: "dachterrasse",
    title: "Dachterrasse"
  },
  {
    key: "garten",
    title: "Garten"
  },
  {
    key: "keller",
    title: "Keller"
  }
];

/**
 *
 * @param {object} props - The component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @example
 */
export default function AreaSettingsModal({ isOpen = false }) {
  const initialFocusReference = useRef(null);
  const { mutate, userSettings } = useUserSettings();

  const toggleAreaModal = useStore((state) => state.toggleAreaModal);

  const [submitting, setSubmitting] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [showAllRatios, setShowAllRatios] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const defaultSettings = {
    balkon: 0,
    dachterrasse: 0,
    garten: 0,
    keller: 0,
    loggia: 100,
    terrasse: 0
  };

  const [storedSettings, setStoredSettings] = useState({
    balkon: 0,
    dachterrasse: 0,
    garten: 0,
    keller: 0,
    loggia: 0,
    terrasse: 0
  });

  const [changedSettings, setChangedSettings] = useState({
    balkon: 0,
    dachterrasse: 0,
    garten: 0,
    keller: 0,
    loggia: 0,
    terrasse: 0
  });

  /**
   *
   * @example
   */
  function handleClose() {
    toggleAreaModal();
    setTimeout(() => {
      setHasChanges(false);
    }, 200);
  }

  /**
   *
   * @param type
   * @example
   */
  function handleRadioChange(type) {
    if (type === "custom") {
      setShowCustom(true);
    }
    else {
      setShowCustom(false);
    }
  }

  /**
   *
   * @param settings
   * @example
   */
  function updateStoredSettings(settings) {
    setStoredSettings({
      balkon: settings.custom_balkon_ratio,
      dachterrasse: settings.custom_dachterrasse_ratio,
      garten: settings.custom_garten_ratio,
      keller: settings.custom_keller_ratio,
      loggia: settings.custom_loggia_ratio,
      terrasse: settings.custom_terrasse_ratio
    });
  }

  /**
   *
   * @param key
   * @param value
   * @example
   */
  function updateChangedValue(key, value) {
    if (hasChanges) {
      setChangedSettings((previous) => ({
        ...previous,
        [key]: value
      }));
    }
    else {
      setChangedSettings({
        ...storedSettings,
        [key]: value
      });
      setHasChanges(true);
    }
  }

  /**
   *
   * @example
   */
  function handleSubmit() {
    setSubmitting(true);
    const submitValues = showCustom && hasChanges ? changedSettings : storedSettings;

    updateUserSettings({
      custom_balkon_ratio: submitValues.balkon,
      custom_dachterrasse_ratio: submitValues.dachterrasse,
      custom_garten_ratio: submitValues.garten,
      custom_keller_ratio: submitValues.keller,
      custom_loggia_ratio: submitValues.loggia,
      custom_terrasse_ratio: submitValues.terrasse,
      show_all_ratios: showAllRatios,
      use_custom_area_ratios: showCustom
    }, handleSuccess, handleError);
  }

  /**
   *
   * @example
   */
  function handleSuccess() {
    handleClose();
    setSubmitting(false);
    mutate();
    showSuccess("user-settings-success");
  }

  /**
   *
   * @param status
   * @example
   */
  function handleError(status) {
    setSubmitting(false);
    showError("user-settings-error");
  }

  useEffect(() => {
    if (isOpen && userSettings) {
      setShowCustom(userSettings.use_custom_area_ratios);
      setShowAllRatios(userSettings.show_all_ratios);
      updateStoredSettings(userSettings);
    }
  }, [isOpen, userSettings]);

  return (
    <Dialog
      className="fixed inset-0 z-[100] h-full overflow-y-auto lg:h-auto"
      initialFocus={initialFocusReference}
      onClose={handleClose}
      open={isOpen}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-200 ease-out data-[closed]:opacity-0"
      />

      <div className="relative flex h-full min-h-full items-center justify-center p-4 text-center sm:p-0 lg:h-auto">
        <DialogPanel
          transition
          className="flex size-full flex-col overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all duration-200 ease-out data-[closed]:translate-y-4 data-[closed]:opacity-0 sm:my-8 sm:max-w-3xl sm:data-[closed]:translate-y-0 sm:data-[closed]:scale-95"
        >
          <div className="relative border-b border-gray-200 p-4 sm:px-6">
            <div className="flex items-center justify-center text-lg font-medium">
              <ScaleIcon className="-mt-0.5 size-8 rounded-full bg-custom-area-ratio p-1 text-white" />

              <p className="ml-3">Anteilsberechnung von Freiflächen und Zubehör</p>
            </div>

            <div className="absolute right-4 top-0 hidden h-full items-center sm:flex">
              <button
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                onClick={handleClose}
                type="button"
              >
                <CloseIcon className="size-5 text-black" />
              </button>
            </div>
          </div>

          <div className="h-full max-h-screen-66 divide-y divide-gray-300 overflow-y-scroll bg-white px-4 pb-5 sm:max-h-screen-80 sm:divide-gray-200 sm:px-12">

            <div className="py-10">
              <div className="space-y-1.5">

                <div className="inline-flex cursor-pointer items-center">
                  <input
                    checked={!showCustom}
                    className="size-5 cursor-pointer border-gray-300 text-custom-area-ratio focus:outline-none focus:ring-0 focus:ring-white"
                    id="default"
                    name="area-settings"
                    onChange={() => handleRadioChange("default")}
                    type="radio"
                  />

                  <label className="ml-3 block cursor-pointer font-medium text-gray-700" htmlFor="default">
                    Standardgewichtung
                  </label>
                </div>

                <div className="flex cursor-pointer items-center">
                  <input
                    checked={showCustom}
                    className="size-5 cursor-pointer border-gray-300 text-custom-area-ratio focus:outline-none focus:ring-0 focus:ring-white"
                    id="custom"
                    name="area-settings"
                    onChange={() => handleRadioChange("custom")}
                    type="radio"
                  />

                  <label className="ml-3 block cursor-pointer font-medium text-gray-700" htmlFor="custom">
                    individuelle Flächengewichtung
                  </label>
                </div>

              </div>

              <div className="mt-10 space-y-4">
                {sliders.map((slider) => (
                  <AreaSettingsSliderContainer
                    changeKey={slider.key}
                    changedValue={changedSettings[slider.key]}
                    defaultValue={defaultSettings[slider.key]}
                    hasChanges={hasChanges}
                    key={slider.key}
                    showCustom={showCustom}
                    storedValue={storedSettings[slider.key]}
                    title={slider.title}
                    updateChangedValue={updateChangedValue}
                  />
                ))}
              </div>
            </div>

          </div>

          <div className="space-y-4 border-t border-gray-200 p-4 text-center sm:flex sm:flex-row-reverse sm:items-center sm:justify-between sm:space-y-0 sm:px-6">
            <button
              className="mx-auto mt-2 flex h-11 w-64 items-center justify-center rounded-md border border-transparent bg-primary text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none sm:mx-0 sm:mt-0"
              disabled={submitting}
              onClick={handleSubmit}
              type="button"
            >
              {submitting && (
                <svg className="size-5 animate-spin text-white" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />

                  <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
                </svg>
              )}

              {!submitting && <span>Änderungen speichern</span>}
            </button>

            <button
              className="-ml-2.5 rounded-md px-2.5 py-1.5 font-medium text-gray-700 underline hover:bg-gray-100 focus:outline-none"
              onClick={handleClose}
              ref={initialFocusReference}
              type="button"
            >
              Schließen
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
